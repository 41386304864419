import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0;
  .logout-button {
    position: absolute;
    right: 20px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: #0071cd;
  }
  p {
    &.small {
      font-size: 14px;
    }
  }
  .no-border {
    border: 0 !important;
  }
  .align-center {
    text-align: center;
  }
  .add-top {
    margin-top: 40px;
  }
  .center {
    text-align: center;
  }
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 100%;
    padding: 0;
    margin-top: -70px;
    .login-wrapper {
      display: flex;
      width: 100%;
      height: 100vh;
      .welcome-section {
        background-color: #0071cd;
        color: white;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }

      .login-section {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .avatar {
          width: 300px;
          margin-bottom: 50px;
        }
        button {
          min-width: 250px;
          color: #fff;
        }
      }
    }
  }

  .dashboard {
    padding: 0 30px 30px;
    .dashboard-wrapper {
      margin-top: 40px;
    }

    .container {
      padding-top: 30px;
    }

    .pie-chart {
      width: 100%;
      max-width: 465px;
      margin: auto;
    }
  }

  th,
  td {
    font-family: "Outfit", sans-serif;
    font-size: 13px;
  }

  td {
    padding: 10px 16px !important;
  }

  footer {
    width: 100%;
    margin-top: 50px;
    padding: 20px 30px;
    font-size: 13px;
  }
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .number-projects {
    height: 400px;
    width: 65%;
  }

  .page-header {
    margin-top: 50px;
    position: relative;
    .link-to-jira {
      position: absolute;
      top: 0;
      right: 0;
      a {
        button {
          svg {
            margin-right: 10px;
            width: 20px;
          }
          color: rgb(0, 113, 205) !important;
          text-transform: none;
        }
      }
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      &.welcome {
        margin: 30px 0 -50px;
        span {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
    .links {
      color: #000;
      font-size: 12px;
      a {
        color: #000;
        &:hover {
          color: #0071cd;
        }
      }
      span {
        margin: 0 10px;
      }
    }
    button {
      color: #fff;
      position: relative;
      top: -30px;
    }
  }

  .summary-item {
    background: #fff;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 5px;
    .flex {
      border-bottom: 1px solid rgb(227, 227, 227);
    }
    h4 {
      color: #000;
      border-bottom: 1px solid rgb(227, 227, 227);
      padding: 15px;
      margin-bottom: 0;
      font-weight: 500;
      span {
        margin-left: 10px;
        &:hover {
          cursor: pointer;
          color: #0071cd;
        }
      }
      svg {
        width: 18px;
        position: relative;
        top: 3px;
      }
    }
    .type {
      background: rgba(67, 185, 178, 0.1);
      border-left: 1px solid rgb(227, 227, 227) !important;
      h4 {
        &.budget-spending {
          text-align: center;
          font-size: 14px;
          padding: 12px 15px 5px;
          span {
            font-size: 12px;
            margin-left: 0;
            &:hover {
              color: #000;
            }
          }
        }
      }
      &.over-budget {
        background: rgba(253, 126, 64, 0.1);
        h4 {
          color: rgb(253, 126, 64);
          span {
            cursor: auto;
            &:hover {
              color: rgb(253, 126, 64);
            }
          }
        }
      }
    }
    .top-legend {
      display: flex;
      position: relative;
      top: 16px;
      right: 20px;
      p {
        font-size: 13px;
        span {
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin-right: 5px;
          position: relative;
          top: -2px;
          margin-left: 10px;
          &.ontrack {
            background: #43b9b2;
          }
          &.at-risk {
            background: #fd7e40;
          }
        }
      }
    }
    .detail-item {
      padding: 15px;
      &.pmo-development {
        justify-content: flex-start;
      }
      &.centered {
        justify-content: center !important;
        align-items: center;
        height: 100%;
      }
      &.no-border {
        border: 0;
      }
      .flex {
        border: 0;
      }
      p {
        line-height: 200%;
      }
      .item {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 150px;
        height: 150px;
        &.team {
          text-align: left;
          flex: 0 0 66%;
          h5 {
            font-size: 16px;
            margin-bottom: 5px;
          }
        }
        &.big {
          max-width: 230px;
        }
        &.small {
          padding-top: 40px;
          max-width: 100px;
          h3 {
            padding-top: 10px;
          }
        }
        &.pmo {
          padding-top: 30px;
          max-width: 130px;
        }
        h6 {
          padding-top: 8px;
        }
        p {
          font-size: 16px;
        }
        &.ontrack {
          background: #43b9b21a;
          p,
          span,
          h6 {
            color: #43b9b2;
          }
        }
        &.inprogress {
          background: #fec84d1a;
          p,
          span,
          h6 {
            color: #fec84d;
          }
        }
        &.at-risk {
          background: #fd7e401a;
          p,
          span,
          h6 {
            color: #fd7e40;
          }
        }
        &.default {
          background: #55505c1a;
        }
        span {
          font-size: 13px;
        }
      }
      .legend {
        padding-right: 20px;
        ul,
        li {
          list-style: none;
          font-size: 13px;
          span {
            padding-left: 10px;
            font-weight: bold;
          }
        }
      }
      .divider {
        border-left: 1px solid rgb(227, 227, 227);
      }
      .cancelled {
        text-align: center;
        padding-right: 25px;
        p {
          padding-top: 40px;
          span {
            font-weight: 700;
            font-size: 22px;
          }
        }
      }
      .chart {
        &.small {
          width: 200px;
          height: 80px;
          position: relative;
          top: 40px;
        }
      }
    }
    .legend {
      padding-top: 20px;
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 300%;
        span {
          font-weight: 300;
          float: right;
          padding-left: 40px;
          &.dot {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            float: left;
            padding: 0;
            position: relative;
            top: 18px;
            margin-right: 10px;
          }
          &.completed {
            background: #00b1b0;
          }
          &.inprogress {
            background: #fec84d;
          }
          &.to-start {
            background: #ff8370;
          }
        }
      }
    }

    &.chart {
      width: 350px;
      height: 100px;
      position: relative;
      p {
        &.percentage {
          color: #000;
          position: absolute;
          font-size: 24px;
          font-weight: bold;
          top: 70%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 0;
      &.note {
        font-size: 14px;
      }
    }
  }

  .roadmap {
    position: relative;
    width: 100%;
    max-width: 1300px;
    margin: 40px auto;
  }
  .sdt {
    margin-right: 30px;
    width: 40%;
    p {
      &.note {
        font-size: 13px;
        float: right;
        color: #999;
      }
    }
    ul {
      list-style: none;
      margin-left: 0;
      margin-top: 15px;
      li {
        &:hover {
          background: #f4f4f4;
          cursor: pointer;
        }
        span {
          float: right;
        }
        border-top: 1px solid #ddd;
        margin-left: 0;
        padding: 10px;
        margin-bottom: 0;
        &:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
  .add-project {
    position: fixed;
    bottom: 20px;
    right: 40px;
    border-radius: 50%;
    background: #0071cd;
  }
  .project-list-container {
    position: relative;
    .project-table-wrapper {
      table {
        th {
          padding-left: 15px;
          background: rgb(227, 227, 227) !important;
        }
      }
      .MuiPaper-root {
        box-shadow: none !important;
        border: 1px solid rgb(227, 227, 227);
        padding-top: 15px;
      }
    }

    .actions {
      justify-content: flex-start;
      position: relative;
      top: -30px;
      left: 10px;
      z-index: 9;
      width: 400px;
      p {
        position: relative;
        top: 10px;
        margin-right: 15px;
        font-weight: bold;
      }
      button {
        margin-right: 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #333;
        &.active {
          background: #0071cd;
          border-color: #0071cd;
          color: #fff;
        }
        &.reset {
          border: 0;
        }
      }
    }
  }

  .budget-detail {
    margin: 30px 0 60px;
    .flex {
      width: 80%;
      margin: auto;
      .dev,
      .qa {
        width: 45%;
        padding: 20px;
        font-size: 24px;
        font-weight: bold;
        span {
          font-size: 48px;
        }
      }

      .dev {
        background: rgba(0, 177, 176, 0.4);
        border-radius: 5px;
      }
      .qa {
        background: rgba(255, 131, 112, 0.4);
        border-radius: 5px;
        text-align: right;
      }
    }
  }

  .budget-dashboard {
    margin-top: 40px;
    table {
      th {
        padding-left: 15px;
        background: rgb(227, 227, 227) !important;
      }
    }
    .MuiPaper-root {
      box-shadow: none !important;
      border: 1px solid rgb(227, 227, 227);
      padding-top: 15px;
    }
  }

  .project-list-wrapper {
    margin-top: 30px;

    .table-wrapper {
      background: #f4f4f4;
      padding: 10px 30px 30px;
      margin-top: 40px;
      border-radius: 5px;
    }
  }

  .budget-summary {
    margin: 60px 0;
    padding: 50px 20px;
    .item {
      background: #00b1b0;
      &:first-child {
        background: #ff8370;
      }
    }
  }

  p {
    &.remark {
      text-align: right;
      color: red;
      font-size: 12px;
      padding-top: 10px;
    }
    &.overview {
      margin-top: -60px;
      padding-top: 0;
      padding-right: 30px;
      margin-bottom: 30px;
    }
  }
  .managed-service {
    padding: 40px 20px;
  }
  .select-label {
    top: -7px;
  }
  .no-data {
    text-align: center;
    svg {
      width: 300px;
      margin: 40px auto;
    }
    button {
      background: #00b1b0;
      color: #fff;
      padding: 7px 25px;
      border-radius: 5px;
      margin-top: 40px;
    }
  }

  .toggle {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 50px 0 -30px;
    p {
      position: relative;
      top: -18px;
      margin-right: 15px;
    }
    button {
      color: #000;
      text-transform: none;
      padding: 5px;
      border: 1px solid rgb(227, 227, 227);
      svg {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
  .height245 {
    max-height: 245px !important;
  }
  .no-permission {
    text-align: center;
    svg {
      width: 20px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
    font-size: 18px;
  }
`;
export const ModalWrapper = styled.div`
  section {
    &.color {
      background: #ebf4f6;
    }
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 20px;
    h5 {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: -10px;
      span {
        &.delete {
          margin-bottom: -30px;
          float: right;
          position: relative;
          top: -15px;
          svg {
            width: 20px;
            color: red;
          }
        }
      }
    }
  }
  .select-label {
    top: -7px;
  }
  p {
    &.sub-head {
      font-weight: 700;
      font-size: 13px;
      margin-bottom: 0;
      color: #0071cd;
    }
    &.benefit-note {
      font-size: 14px;
    }
  }
  .project-detail {
    min-width: 600px;
  }

  .button {
    margin: 20px;
    text-align: right;
  }
  span {
    &.status {
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      padding: 5px 10px;
      &.no-status {
        border-color: #fff;
      }
      &.imp {
        border-color: #276fbf;
        color: #276fbf;
      }
      &.completed {
        border-color: #0071cd;
        color: #0071cd;
      }
    }
  }
  .editing-project {
    .title {
      padding-right: 150px;
    }
  }
  .modal-actions {
    button {
      min-width: 100px;
      height: 40px;
      border-radius: 5px;
      &.submit {
        background: #0071cd;
        color: #fff;
      }
    }
  }

  .add-remove {
    position: relative;
    display: flex;
    top: -5px;
    svg {
      width: 30px;
    }
  }
`;
